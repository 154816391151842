<template>
  <div>
    <div v-if='hasManySessionRunningDays'
      class='flex flex-row justify-between mb-4 lg:mb-12'>
      <div v-for='(dailySessions) in sessionsByDateForIcola'
        :key='`dailySessions-${dailySessions.date}`'
        class='program-date-selector'
        :class='programDateSelectorActiveClass(dailySessions.date)'
        @click='updateSessionsByDay(dailySessions.date)'>
        <div>{{ dateString(dailySessions.date) }}</div>
      </div>
    </div>
    <program-by-day v-if='showDay1'
    class='mt-4 lg:mt-12'
    :daily-sessions='sessionsByDateForIcola[1]'
    :show-schedule-full-width='showScheduleFullWidth'
    :program-slot-size='programSlotSize'
    :event-program-mobile-layout='eventProgramMobileLayout' />
    <program-by-day v-if='showDay2'
    class='mt-4 lg:mt-12'
    :daily-sessions='sessionsByDateForIcola[2]'
    :show-schedule-full-width='showScheduleFullWidth'
    :program-slot-size='programSlotSize'
    :event-program-mobile-layout='eventProgramMobileLayout' />
    <program-by-day v-if='showDay3'
    class='mt-4 lg:mt-12'
    :daily-sessions='sessionsByDateForIcola[3]'
    :show-schedule-full-width='showScheduleFullWidth'
    :program-slot-size='programSlotSize'
    :event-program-mobile-layout='eventProgramMobileLayout' />
  </div>
</template>

<script>
import ProgramByDay          from '@/components/programs/ProgramByDay.vue'
import DateTimeStringHelpers from '@/utils/date-time-string-helpers'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import utc      from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'ProgramSchedule',
  props: [
    'defaultToToday',
    'showScheduleFullWidth',
    'programSlotSize',
  ],
  data () {
    return {
      selectedDate: 'all',
    }
  },
  components: {
    ProgramByDay,
  },
  watch: {
    currentQueryParamProgramDay: {
      handler: function (newVal) {
        if (newVal) { 
          this.selectedDate = newVal
        }
      }, 
      immediate: true
    },
    sessionsDateCount: {
      handler: function (newVal) { 
        if (newVal > 1 && this.defaultToToday) { // and the default is a valid index
          this.updateSessionsByDay(this.defaultDate)
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters('sessions', [
      'sessionsByDate',
      'hasManySessionRunningDays',
      'sessionDates',
    ]),
    ...mapGetters('events', [
      'dateLocaleString',
      'eventProgramMobileLayout',
    ]),
    defaultDate () {
      let todaysDate = dayjs(new Date()).tz("Asia/Seoul").format('YYYY-MM-DD')
      let index = this.sessionDates.findIndex(date => date === todaysDate)
      if (index >= 0) {
        return todaysDate
      } else {
        return 'all'
      } 
    },
    hasProgramQuery () {
      return this.$route.query.program_day // this should use the human readable version
    },
    currentQueryParamProgramDay () {
      return (this.hasProgramQuery) ? this.$route.query.program_day : 'all'
    },
    sessionsByDateForIcola () {
      return [{date: 'all'}, ...this.sessionsByDate]
    },
    showDay1 () {
      return ['all', '2023-09-14'].includes(this.currentQueryParamProgramDay)
    },
    showDay2 () {
      return ['all', '2023-09-15'].includes(this.currentQueryParamProgramDay)
    },
    showDay3 () {
      return ['all', '2023-09-16'].includes(this.currentQueryParamProgramDay)
    },
  },
  methods: {
    updateSessionsByDay (date) {
      if (this.selectedDate !== dayjs(date).format('YYYY-MM-DD')) {
        this.updateProgramDayQueryParam(date)
      }
    },
    programDateSelectorActiveClass (day) {
      let formattedDay = day === 'all' ? 'all' : dayjs(day).format('YYYY-MM-DD')
      return (this.selectedDate === formattedDay) ? 'is_active' : ''
    },
    dateString (date) {
      return (date.toLowerCase().includes('all')) ? 'All Dates' : DateTimeStringHelpers.toDateWithoutYear(date, this.dateLocaleString)
    },
    updateProgramDayQueryParam (newDate) {
      this.$router.push({ query: {...this.$route.query, program_day: newDate} })
    },
  },
}
</script>

<style type='scss' scoped>
.program-date-selector {
  @apply flex-grow border text-center py-4 text-sm font-semibold cursor-pointer;
}

.program-date-selector.is_active {
  background-color: var(--eventMainColor);
  color: #FFF;
}

@media (min-width: 1024px) {
  .program-date-selector:hover {
    background-color: var(--eventMainColor);
    color: #FFF;
  }
}
</style>
